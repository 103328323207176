<template>
  <app-modal-mini
    :submit-txt="__t('checkout.validation.notification.submit')"
    :hide-cancel="minOrder"
    @submitted="modalController.dismiss"
  >
    <template #header>
      <h3>
        <Icon name="info" />
        {{ __t('checkout.validation.order.title') }}
      </h3>
    </template>

    <template #main>
      <div class="messages">
        <ul v-if="messages.length > 0">
          <li v-for="(message, i) in messages" :key="`message_${i}`">
            {{ message }}
          </li>
        </ul>
      </div>

      <div class="link">
        <span @click="gotTo('cart')">
          <Icon name="bag" />
          {{ __t('app.continue-shopping') }}
        </span>
      </div>
    </template>
  </app-modal-mini>
</template>

<script>
  import {Icon} from "@/ui";
  import {__t} from "@/globals";
  import {modalController} from "@ionic/vue";
  import AppModalMini from "@/modals/AppModalMini.vue";

  export default {
    name: "ModalNotification",
    components: {Icon, AppModalMini},
    props: {
      messages: { type: Array, required: false, default: new Array(0) },
      minOrder: {type: Boolean, required: false, default: false}
    },
    computed: {
      modalController() {
        return modalController
      }
    },

    methods: {__t, gotTo(name) {
      this.modalController.dismiss();
      this.$router.push({name: name});
    }
    },
  }
</script>

<style lang="scss">
.ion-modal-notification {
  --height: 42rem;
  .messages {
    background-color: var(--r2e-primary-100);
    padding: var(--padding-m) var(--padding-m);
    border: 1px solid var(--r2e-error);

    ul li {
      list-style: disc;
      margin-left: var(--margin-s);
      font-size: 1.3rem;
      color: var(--r2e-error);
      font-weight: 500;
      margin-bottom: var(--margin-s);
    }
  }

  .link {
    display: flex;
    justify-content: center;
    margin-top: var(--margin-m);
    span {
      display: flex;
      border: 2px solid var(--r2e-primary);
      width: max-content;
      border-radius: 99rem;
      font-size: 1.2rem;
      padding: 0.4rem 1.2rem;
      line-height: 1;
      font-weight: 600;
      text-transform: lowercase;
      color: var(--r2e-primary);

      svg {
        margin-right: var(--margin-s);
        width: 1.2rem;
        height: 1.2rem;
        color: var(--r2e-primary);
      }
    }
  }


}

[data-theme='dark'] {
  .messages {
    background-color: var(--r2e-primary-700);
  }
}

</style>
